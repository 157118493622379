import { extend, localize } from 'vee-validate'
import ar from 'vee-validate/dist/locale/ar.json'

localize({
  ar: {
    messages: ar.messages,
    names: {
      email: 'البريد الإلكتروني',
      password: 'كلمة السر',
      phone1: 'رقم التليفون',
      phone2: 'رقم التليفون',
      name: 'الإسم',
      area: 'المنطقة',
      city: 'المحافظة',
      mapLocation: 'العنوان على الخريطة',
      confirmed: 'كلمة المرور',
      title: 'العنوان',
      description: 'الوصف',
      price1Day: 'السعر ليوم واحد',
      price3Day: 'السعر ل ٣ أيام',
      price7Day: 'السعر لاسبوع',
      branches: 'الأفرع',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
})

localize('ar')

const validateLocation = location => {
  if (location == null) {
    return false
  }

  if (location.city == null) {
    return false
  }

  if (location.area == null) {
    return false
  }

  return true
}

export const locationRequired = extend('location', {
  validate: validateLocation,
  message: 'Please enter positive number!',
})

export const phone = extend('phone', {
  validate: validateLocation,
  message: 'Please enter positive number!',
})
