import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'account-settings' },
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import('@/views/account-setting/AccountSetting.vue'),
    },
    {
      path: '/account-settings/branches/:uid/edit',
      name: 'account-settings-edit-branch',
      component: () => import('@/views/account-setting/branches/BranchesEditBranch.vue'),
    },
    {
      path: '/account-settings/branches/add-branch',
      name: 'account-settings-add-branch',
      component: () => import('@/views/account-setting/branches/BranchesAddNewBranch.vue'),
    },
    {
      path: '/ads/:slug/edit',
      name: 'edit-ad',
      component: () => import('@/views/ads/AdsEditAd.vue'),
    },
    {
      path: '/ads/create',
      name: 'create-ad',
      component: () => import('@/views/ads/AdsCreateAd.vue'),
    },
    {
      path: '/ads',
      name: 'list-ads',
      component: () => import('@/views/ads/AdsList.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = store.getters['user/isLogged']

  const authRoutes = ['login', 'forgot-password', 'reset-password']

  if (isLoggedIn && authRoutes.includes(to.name)) {
    return next('/')
  }

  if (isLoggedIn === false && authRoutes.includes(to.name) === false) {
    return next({ name: 'login' })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
