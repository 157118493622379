export default {
  namespaced: true,
  state: {
    cities: [],
    areas: [],
  },

  getters: {
    cities(state) {
      return state.cities.cities
    },

    areas(state) {
      return state.cities.areas
    },
  },

  mutations: {
    setCities(state, cities) {
      localStorage.setItem('cities', JSON.stringify(cities))
      state.cities = cities
    },

    setFromLocalStorage(state) {
      const cities = localStorage.getItem('cities')
      if (cities) {
        state.cities = JSON.parse(cities)
      }
    },
  },
}
