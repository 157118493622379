export default {
  namespaced: true,
  state: {
    branch: null,
  },

  mutations: {
    setBranch(state, branch) {
      state.branches = branch
    },
  },

  getters: {
    branch(state) {
      return state.branch
    },
  },
}
