import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import * as VueGoogleMaps from 'vue2-google-maps'
import gmap from '@/libs/gmap'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// Axios Mock Adapter
// import '@/@fake-db/db'

import '@/@core/utils/validations/validations'
import '@/libs/validations/validations'

Vue.filter('str_limit', (value, size) => {
  if (!value) {
    return ''
  }
  const newValue = value.toString()

  if (newValue.length <= size) {
    return value
  }
  return `${newValue.substr(0, size)}...`
})

Vue.use(VueGoogleMaps, {
  load: {
    key: gmap.apiKey,
    libraries: 'places',
    region: 'egypt',
    language: 'ar',
  },
  installComponents: true,
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
