import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store'

const token = store.getters['user/token']
const headers = {}
if (token) {
  headers.Authorization = `Bearer ${token}`
}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  headers,
})

Vue.prototype.$http = axiosIns

export default axiosIns
