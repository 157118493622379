export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
  },

  getters: {
    token(state) {
      const authToken = localStorage.getItem('authToken')
      return state.token || authToken
    },

    isLogged(state) {
      const authToken = localStorage.getItem('authToken')
      return !!(state.token || authToken)
    },

    user(state) {
      const userData = localStorage.getItem('userData')
      return state.user || JSON.parse(userData)
    },
  },

  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('authToken', token)
    },

    setUser(state, user) {
      state.user = user
      localStorage.setItem('userData', JSON.stringify(user))
    },

    setFromLocalStorage(state) {
      const userData = localStorage.getItem('userData')
      const authToken = localStorage.getItem('authToken')

      state.user = userData ? JSON.parse(userData) : null
      state.authToken = authToken || null
    },

    addBranch(state, branch) {
      state.user.branches.push(branch)
    },

    deleteBranch(state, index) {
      state.user.branches.splice(index, 1)
      localStorage.setItem('userData', JSON.stringify(state.user))
    },

    login(state, authData) {
      state.user = authData.user
      state.token = authData.token

      localStorage.setItem('authToken', authData.token)
      localStorage.setItem('userData', JSON.stringify(authData.user))
    },

    logout(state) {
      localStorage.removeItem('authToken')
      localStorage.removeItem('userData')
      state.token = null
      state.user = null
    },
  },
}
