export default {
  namespaced: true,
  state: {
    categories: {
      nested: [],
      flat: [],
    },
  },

  mutations: {
    setCategories(state, categories) {
      state.categories = categories
      localStorage.setItem('categories', JSON.stringify(categories))
    },

    setFromLocalStorage(state) {
      const categories = localStorage.getItem('categories')
      if (categories) {
        state.categories = JSON.parse(categories)
      }
    },
  },

  getters: {
    nestedCategories(state) {
      if (state.categories.length === 0) {
        return []
      }

      return JSON.parse(JSON.stringify(state.categories.nested))
    },

    flatCategories(state) {
      if (state.categories.length === 0) {
        return []
      }

      return JSON.parse(JSON.stringify(state.categories.flat))
    },
  },
}
