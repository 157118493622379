export default {
  apiKey: 'AIzaSyC_ZTodDJhFkA46GKYVkF7XymNITgyEwRc',
  center: {
    center: {
      lat: 30,
      lng: 31,
    },
    zoom: 10,
  },
  options: {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    disableDefaultUi: true,
    gestureHandling: 'greedy',
  },
}
